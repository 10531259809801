<template>
  <div>
    <div ref="pie" style="height: 500px; width: 100%;"></div>
  </div>
</template>

<script>
import { isResOK } from "@/api/response";
import {getIncomeDistribution} from "@/api/income_statistics";
export default {
  name: "AreaIncomeDistribution",
  data(){
    return{
        data:[],
    }
  },
  computed: {
    // 图表默认配置
    chartOption() {
      return {
        title: {
          text: '区域单台设备累计收益分布',
          left: 'center',
          padding: [20, 0, 0, 0],
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          x: 'center',
          y: 'bottom',
          orient: 'vertical',
          data: []
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '50%'],
            center:['50%','50%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 16,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [],
          }
        ],
        queryForm: {},
      }
    },
  },
  methods:{
    fetchData(search) {
      this.queryForm = search
      getIncomeDistribution(this.queryForm).then((res) => {
        if (isResOK(res)) {
          this.data = res.data;
          this.drawChart();
        }
      });
    },
    drawChart() {
      this.chartOption.legend.data = []
      this.chartOption.series[0].data = []
      this.chartOption.legend.data = []
      this.data.forEach((item) => {
        this.chartOption.legend.data.push(item.label+`:  ${item.value}`)
        this.chartOption.series[0].data.push({
          name: item.label+`:  ${item.value}`,
          value: item.value,
        })
      })
      this.chart ||= this.$echarts.init(this.$refs.pie)
      // 全部重新加载-解决tooltip.formatter params不更新的问题
      this.chart.setOption(this.chartOption, true)
      // 添加表格自适应
      window.addEventListener('resize', () => {
        this.chart.resize()
      })
    },
  }
};
</script>

<style scoped></style>
