<template>
  <div>
    <el-form ref="formRef" :inline="true" :model="formState" label-width="85px" label-position="left">
      <el-row>
        <el-col
            :span="8"
        >
          <el-form-item label="地区选择" prop="areaId">
            <el-cascader
                :key="cascaderKey"
                style="width: 325px"
                v-model="areaId"
                :options="areaOptions"
                :disabled="loading"
                :props="{value: 'orgId',label: 'orgName',children: 'subOrgList',checkStrictly: true,}"
                @change="handleChangeArea"
                clearable
            />
          </el-form-item>
        </el-col>
        <el-col
            :span="8"
        >
          <el-form-item label="项目类型" prop="projectTypeId">
            <el-select
                style="width: 325px"
                v-model="formState.projectTypeId"
                clearable
                :placeholder="!areaId || areaId.length === 0?  '请先选择地区':'请选择项目类型'"
                :disabled="!areaId || areaId.length === 0"
            >
              <el-option
                  v-for="item in productTypeOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
            :span="8"
        >
          <el-form-item label="项目名称" prop="name">
            <el-input
                style="width: 325px"
                v-model="formState.projectName"
                :placeholder="!areaId || areaId.length === 0?  '请先选择地区':'请输入项目名称'"
                :disabled="!areaId || areaId.length === 0"
                clearable
            />
          </el-form-item>
        </el-col>
        <el-col
            :span="8"
        >
          <el-form-item label="单台设备累计收益范围" label-width="170px" prop="incomeRange">
            <el-select
                style="width: 240px"
                v-model="formState.incomeRangeTypeCode"
                clearable
                :placeholder="!areaId || areaId.length === 0?  '请先选择地区':'请选择单台设备累计收益范围'"
                :disabled="!areaId || areaId.length === 0"
            >
              <el-option
                  v-for="item in incomeRangeOption"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
            :span="8"
        >
          <el-form-item label="单台设备平均收益范围" label-width="170px" prop="averageIncomeRange">
            <el-select
                style="width: 240px"
                v-model="formState.avgIncomeRangeTypeCode"
                clearable
                :placeholder="!areaId || areaId.length === 0?  '请先选择地区':'请选择单台设备平均收益范围'"
                :disabled="!areaId || areaId.length === 0"
            >
              <el-option
                  v-for="item in averageIncomeRangeOption"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
            :span="6"
        >
          <el-form-item>
            <el-button type="primary" @click="handleSearch">搜索</el-button>
            <el-button type="warning" @click="handleReset">刷新</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {getProjectTypeOptions} from "@/api/project_type";
import {isResOK, isSysResOK} from "@/api/response";
import {
  getAllIncomeRangeOptions,
  getAvgIncomeRangeOptions,
} from "@/api/income_statistics";
import {queryOrgRelTree} from "@/api/system";
import {areaOptions} from "@/api/mock";

export default {
  name: "SearchProject",
  emits: ["submit"],

  data() {
    return {
      formState: {},
      productTypeOptions: [],
      averageIncomeRangeOption: [],
      incomeRangeOption: [],
      areaOptions: [],
      areaId: [],
      total: 0,
      cascaderKey: 0,
      loading: false,
    }
  },
  created() {
    this.getProductTypeOptions();
    this.queryOrgRelTree();
    this.getAverageIncomeRangeOption();
    this.getIncomeRangeOption();
  },
  methods: {
    handleChangeArea(val) {
      if (val !== null && val !== undefined) {
        this.formState.areaId = val.join(",");
      } else {
        this.formState.areaId = undefined;
        this.formState = {}
      }
    },
    queryOrgRelTree() {
      this.loading = true
      queryOrgRelTree({
        orgId: "1681542405985234945",
        relType: "1",
      }).then((res) => {
        if (isSysResOK(res)) {
          this.areaOptions.push(res.result.orgRelInfoVo);
          this.loading = false
        }
      });
    },
    getProductTypeOptions() {
      getProjectTypeOptions().then((res) => {
        if (isResOK(res)) {
          this.productTypeOptions = res.data;
        }
      });
    },
    getIncomeRangeOption() {
      getAllIncomeRangeOptions().then((res) => {
        if (isResOK(res)) {
          this.incomeRangeOption = res.data;
        }
      });
    },
    getAverageIncomeRangeOption() {
      getAvgIncomeRangeOptions().then((res) => {
        if (isResOK(res)) {
          this.averageIncomeRangeOption = res.data;
        }
      });
    },
    handleSearch() {
      this.$emit("submit", this.formState, false);
    },

    handleReset() {
      this.$refs.formRef.resetFields()
      this.areaId = []
      this.cascaderKey = new Date().getTime()
      this.formState = {}
      this.$emit("submit", this.formState, true)
    },
  },
};
</script>
