<template>
  <div>
    <el-row>
      <el-col :span="14">
        <el-date-picker
            style="width: 400px"
            v-model="duringDate"
            :type="dateType"
            range-separator="To"
            :start-placeholder="dateType==='daterange'? '开始日期':'开始月份'"
            :end-placeholder="dateType==='daterange'? '结束日期':'结束月份'"
            @change="onConfirmDate"
        />
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="warning" @click="refresh">刷新</el-button>
      </el-col>
      <el-col :span="4">
        <el-radio-group v-model="active" @change="changeDateType">
          <el-radio-button label="day">
            <span>日</span>
          </el-radio-button>
          <el-radio-button label="month">
            <span>月</span>
          </el-radio-button>
        </el-radio-group>
      </el-col>
    </el-row>
    <div v-show="isShowTrend" ref="trend" style="height: 200px; width: 100%"></div>
    <div v-show="!isShowTrend" style="height: 200px;width: 100%;">
      <el-empty
          :image-size="50"
          style="margin-bottom: 20px"
          description="暂无数据"
      />
    </div>
  </div>
</template>
<script>
import {isResOK} from "@/api/response";
import {
  getDeviceIncomeDayStatistics,
  getDeviceIncomeMonthStatistics,
} from "@/api/income_statistics";
import dayjs from "dayjs";
import {getDeviceLocationIncomeStatisticsData} from "@/api/device_location";
import {formatCurrency} from "@/utils/filter";

export default {
  name: "DeviceIncomeTrend",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      active: "day",
      queryParams: {
        beginDate: parseInt(dayjs().subtract(30, "day").format("YYYYMMDD")),
        endDate: parseInt(dayjs().format("YYYYMMDD")),
      },
      showDateRangePicker: false,
      showMonthRangePicker: false,
      data: [],
      duringDate: [dayjs().subtract(30, "day"), dayjs()],
      dateType: 'daterange',
      isShowTrend: true,
    };
  },
  computed: {
    // 图表默认配置
    chartOption() {
      return {
        tooltip: {
          trigger: "axis",
          position: (point, params, dom, rect, size) => {
            // size为当前窗口大小
            if (size.viewSize[0] / 2 >= point[0]) {
              // 其中point为当前鼠标的位置
              return [point[0] + 20, "10%"];
            } else {
              // 其中point为当前鼠标的位置
              return [point[0] - 200, "10%"];
            }
          },

          formatter: (params) => {
            let relVal = params[0].name;
            for (let i = 0; i < params.length; i++) {
              relVal += `<br/>${params[i].marker}
                <span style="width: 100px; overflow: hidden;text-overflow: ellipsis; display: inline-block; vertical-align: bottom">
                ${params[i].seriesName}
                </span>
                &nbsp;&nbsp;
                ${formatCurrency(params[i].value)}`;
            }
            return relVal;
          },
        },
        grid: {
          y: 30, //上下距离
          y2: 36,
          x2: 40,
          x: 50,
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [],
      };
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.data = [];
      if (this.active === "day") {
        this.fetchDayStatistics();
      } else {
        this.fetchMonthStatistics();
      }
    },
    fetchDayStatistics() {
      getDeviceIncomeDayStatistics(Object.assign({}, this.queryParams, {deviceLocationId: parseInt(this.id)})).then((res) => {
        if (isResOK(res)) {
          this.data = res.data.statistics;
          this.isShowTrend = !this.data.every((item) => {
            return item.coinCount === 0;
          })
          this.isShowTrend = this.isShowTrend || !res.data.isShowEmptyImg
          this.drawChart();
        }
      });
    },
    fetchMonthStatistics() {
      getDeviceIncomeMonthStatistics(Object.assign({}, this.queryParams, {deviceLocationId: parseInt(this.id)})).then((res) => {
        if (isResOK(res)) {
          this.data = res.data.statistics;
          this.isShowTrend = !this.data.every((item) => {
            return item.coinCount === 0;
          })
          this.isShowTrend = this.isShowTrend || !res.data.isShowEmptyImg
          this.drawChart();
        }
      });
    },
    // 用于设置渐变色
    setSeries() {
      // 从全局色中获取一个颜色
      this.chartOption.series.push({
        type: "bar",
        data: this.$lodash.map(this.data, "coinCount"),
        name: "收益趋势(元)",
      });
    },
    drawChart() {
      this.chartOption.xAxis.data = [];
      this.chartOption.series = [];
      if (this.data.length > 0) {
        this.chartOption.xAxis.data = this.$lodash.map(this.data, "date");
        this.setSeries();
      }
      this.chart ||= this.$echarts.init(this.$refs.trend);
      // 全部重新加载-解决tooltip.formatter params不更新的问题
      this.chart.setOption(this.chartOption, true);
      // 添加表格自适应
      window.addEventListener("resize", () => {
        this.chart.resize();
      });
    },
    onConfirmDate() {
      if (this.active === "day") {
        this.queryParams.beginDate = parseInt(dayjs(this.duringDate[0]).format("YYYYMMDD"));
        this.queryParams.endDate = parseInt(dayjs(this.duringDate[1]).format("YYYYMMDD"));
      } else {
        this.queryParams.beginDate = parseInt(dayjs(this.duringDate[0]).format("YYYYMM"));
        this.queryParams.endDate = parseInt(dayjs(this.duringDate[1]).format("YYYYMM"));
      }
    },
    changeDateType(e) {
      if (e === "day") {
        this.duringDate = [dayjs().subtract(30, "day"), dayjs()]
        this.queryParams.beginDate = parseInt(dayjs().subtract(30, "day").format("YYYYMMDD"));
        this.queryParams.endDate = parseInt(dayjs().format("YYYYMMDD"));
        this.dateType = 'daterange'
      } else {
        this.duringDate = [dayjs().subtract(11, "month"), dayjs()]
        this.queryParams.beginDate = parseInt(dayjs().subtract(11, "month").format("YYYYMM"));
        this.queryParams.endDate = parseInt(dayjs().format("YYYYMM"));
        this.dateType = 'monthrange'
      }
      this.fetchData();
    },
    search() {
      this.fetchData();
    },
    refresh() {
      this.duringDate = undefined
      if (this.active === "day") {
        this.duringDate = [dayjs().subtract(30, "day"), dayjs()]
        this.queryParams = {
          beginDate: parseInt(dayjs().subtract(30, "day").format("YYYYMMDD")),
          endDate: parseInt(dayjs().format("YYYYMMDD")),
        }
      } else {
        this.duringDate = [dayjs().subtract(11, "month"), dayjs()]
        this.queryParams.beginDate = parseInt(dayjs().subtract(11, "month").format("YYYYMM"));
        this.queryParams.endDate = parseInt(dayjs().format("YYYYMM"));
      }
      this.fetchData();
    },
  },
};
</script>
