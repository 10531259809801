<template>
  <el-dialog
    v-model="isShow"
    title="收益数据"
    width="800px"
    :before-close="handleClose"
  >
    <el-row >
      <el-col :span="24">
        <el-text  style="font-size: 20px;">{{
          deviceLocation.project?.name
        }}</el-text>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5"><span>累计收益(元):</span></el-col>
      <el-col :span="3">
        <span>{{ formatCurrency(deviceLocation.income) }}</span>
      </el-col>
      <el-col :span="5"><span>平均收益(元):</span></el-col>
      <el-col :span="3">
        <span>{{ formatCurrency(deviceLocation.avgIncome) }}</span>
      </el-col>
      <el-col :span="5"><span>今日收益(元):</span></el-col>
      <el-col :span="3">
        <span>{{ deviceLocation.todayIncome ? formatCurrency(deviceLocation.todayIncome) : formatCurrency(0) }}</span>
      </el-col>
    </el-row>
    <device-income-trend :id="id"></device-income-trend>
  </el-dialog>
</template>

<script>
import { getDeviceLocationIncomeStatisticsData } from "@/api/device_location";
import { isResOK } from "@/api/response";
import DeviceIncomeTrend from "@/view/income_statistics/DeviceIncomeTrend.vue";
import {formatCurrency} from "../../utils/filter";
export default {
  name: "DeviceIncomeData",
  components: {
    DeviceIncomeTrend,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },

    id: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:visible', 'completed'],
  data() {
    return {
      deviceLocation: {},
    };
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  created() {
    this.fetchData();
  },

  methods: {
    formatCurrency,
    fetchData() {
      getDeviceLocationIncomeStatisticsData(this.id).then(
        (res) => {
          if (isResOK(res)) {
            this.deviceLocation = res.data;
          }
        }
      );
    },
    handleClose() {
      this.isShow = false
    }
  },
};
</script>
<style lang="css" scoped>
.el-row {
  margin-bottom: 20px;
}


</style>
