
// Numeral.js http://numeraljs.com/
const numeral = require('numeral')

export function formatFloat(value) {
  return numeral(value).format('0.00')
}

export function formatCurrency(value) {
  return numeral(value).format('0,0.00')
}

export function formatBigNumber(value) {
  return numeral(value).format('0,0')
}
