<template>
  <div>
    <el-main>
      <search-income @submit="submitSearch"/>
      <el-empty
          description="请通过搜索查询数据"
          v-show="!searchForm.areaId"
      />
      <div v-show="searchForm.areaId && searchForm.incomeRangeTypeCode || searchForm.avgIncomeRangeTypeCode">
        <el-row>
          <!-- 数据列表 -->
          <el-col v-if="data.length === 0 && !loading">
            <div style="height: 500px;width: 800px;margin-top: 60px;margin-left: 20%">
              <el-empty
                  :image-size="300"
                  style="margin-bottom: 20px"
                  description="暂无数据"
              />
            </div>
          </el-col>
          <el-col v-else v-for="item in data" :key="item.id" :span="7">
            <el-card
                :body-style="{ padding: '0px' }"
                style="margin: 10px 10px"
                @click="detail(item.id)"
            >
              <device-income-list :cardlist="item"></device-income-list>
            </el-card>
          </el-col>
          <el-col>
            <pagination
              v-show="total > 0"
              :total="total"
              v-model:page="query.pageNum"
              v-model:limit="query.pageSize"
              @pagination="fetchData"
            />
          </el-col>
        </el-row>
      </div>
      <div
          v-show="
          searchForm.areaId &&
          !searchForm.incomeRangeTypeCode &&
          !searchForm.avgIncomeRangeTypeCode
        "
      >
        <el-row>
          <el-col :span="16">
            <area-income-trend ref="areaIncomeTrend"/>
          </el-col>
          <el-col :span="8">
            <area-income-distribution
                ref="areaIncomeDistribution"
                :query-form="searchForm"
                :width="windowWidth/3*2"
            />
          </el-col>
        </el-row>
      </div>

      <device-income-data
          v-if="isShowDetailModal"
          :id="detailId"
          v-model:visible="isShowDetailModal"
          @completed="fetchData"
      ></device-income-data>
    </el-main>
  </div>
</template>

<script>
import {getIncomeStatisticsList} from "@/api/income_statistics";
import {isResOK} from "@/api/response";
import SearchIncome from "@/view/income_statistics/Search.vue";
import DeviceIncomeList from "@/view/income_statistics/DeviceIncomeList.vue";
import Pagination from "@/components/Pagination/index.vue";
import DeviceIncomeData from "@/view/income_statistics/DeviceIncomeData.vue";
import AreaIncomeDistribution from "@/view/income_statistics/AreaIncomeDistribution.vue";
import AreaIncomeTrend from "@/view/income_statistics/AreaIncomeTrend.vue";
import store from "@/store";
import {getUrlParams} from "@/api/userList";
import {getCurrentUser} from "@/api/user";

export default {
  name: "",
  components: {
    SearchIncome,
    Pagination,
    DeviceIncomeList,
    DeviceIncomeData,
    AreaIncomeDistribution,
    AreaIncomeTrend,
  },
  emits: ["completed"],
  data() {
    return {
      data: [],
      total: 0,
      query: {
        pageNum: 1,
        pageSize: 12,
      },
      show: false,
      searchForm: {}, //接收search
      detailId: 0,
      isShowDetailModal: false, //是否显示详细信息
      windowWidth: 0,
      loading: false,
    };
  },

  created() {
    this.fetchCurrentUser()
  },

  methods: {
    fetchCurrentUser() {
      store.dispatch('user_info/cleanUser')
      let data = {}
      const params = getUrlParams(window.location.href)
      if (params[`accessToken`]) {
        sessionStorage.setItem('token', params[`accessToken`])
        data[`auth-token`] = params[`accessToken`]
        getCurrentUser(data).then(res => {
          if (isResOK(res)) {
            store.dispatch('user_info/setUser', res.data)
          }
        })
      }
    },

    submitSearch(search, isReset) {
      this.searchForm = JSON.parse(JSON.stringify(search));
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({}, {per_page: this.query.per_page}, search);
      if (!isReset) {
        this.fetchData();
        this.windowWidth = window.innerWidth
      }
    },
    detail(val) {
      this.isShowDetailModal = true;
      this.detailId = val;
    },
    fetchData() {
      if (Object.keys(this.searchForm).length === 0) {
        return false;
      }
      if (Object.keys(this.searchForm).length > 0 && !this.searchForm.areaId) {
        this.$message({
          message: '请选择地区',
          type: 'error',
        })
        return;
      }
      if (this.searchForm.incomeRangeTypeCode || this.searchForm.avgIncomeRangeTypeCode) {
        this.fetchListData();
      } else {
        this.$refs.areaIncomeTrend.fetchData(this.searchForm);
        this.$refs.areaIncomeDistribution.fetchData(this.searchForm);
      }
    },
    fetchListData() {
      this.loading = true;
      this.data = []
      getIncomeStatisticsList(Object.assign({}, this.query))
          .then((res) => {
            if (isResOK(res)) {
              this.data = res.rows;
              this.total = res.total;
              this.loading = false;
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>

<style scoped></style>
