import request from '@/utils/request'

// 设备日统计数据
export function getDeviceIncomeDayStatistics(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/income_day_statistics/trend',
        method: 'get',
        params: query
    })
}

// 地区日统计数据
export function getAreaIncomeDayStatistics(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/income_day_statistics/area_trend',
        method: 'get',
        params: query
    })
}

// 设备月统计数据
export function getDeviceIncomeMonthStatistics(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/income_month_statistics/trend',
        method: 'get',
        params: query
    })
}

// 地区月统计数据
export function getAreaIncomeMonthStatistics(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/income_month_statistics/area_trend',
        method: 'get',
        params: query
    })
}

// 收益统计列表
export function getIncomeStatisticsList(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/income_statistics/list',
        method: 'get',
        params: query
    })
}


// 收益分布接口
export function getIncomeDistribution(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/income_statistics/income_distribution',
        method: 'get',
        params: query
    })
}

// 收益范围选项累计
export function getAllIncomeRangeOptions() {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/income_statistics/all_income_range_options',
        method: 'get',
    })
}

// 收益范围选项平均
export function getAvgIncomeRangeOptions() {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/income_statistics/avg_income_range_options',
        method: 'get',
    })
}
