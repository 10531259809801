<template>
  <div>
    <el-table
      :data="tablelist"
      row-key="id"
      :show-header="false"
      v-loading="loading"
    >
      <el-table-column width="100">
        <template #default="scope">
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <el-image
              style="width: 49px; height: 49px"
              :src="backImgUrl"
              fit="contain"
            />
            <el-image
              style="width: 23px; height: 22px; position: absolute"
              :src="imgUrl"
              fit="contain"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column>
        <template #default="scope">
          <el-row :span="24">
            <el-col :span="24">
              <span>{{ scope.row.project?.name }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-descriptions>
                <el-descriptions-item label="累计收益(元)：" span="1" width="200">{{ formatCurrency(scope.row.income) }}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions>
                <el-descriptions-item label="平均收益(元)：" span="1" width="200">{{ formatCurrency(scope.row.avgIncome) }}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions>
                <el-descriptions-item label="今日收益(元)：" span="1" width="200">{{ scope.row.todayIncome?formatCurrency(scope.row.todayIncome):formatCurrency(0) }}</el-descriptions-item>
              </el-descriptions>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {formatCurrency} from "@/utils/filter";

export default {
  name: "DeviceIncomeList",
  props: {
    cardlist: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statusBgColor: {
        tracking: "#8080FF",
        signing: "#409eff",
        signed: "#039D12",
        suspend: "#F59A23",
        terminated: "#AAAAAA",
      },
      imgUrl: require(`/src/assets/project.png`),
      backImgUrl: require(`/src/assets/project_back.svg`),
      tablelist: [],
    };
  },
  created() {
    this.list();
  },
  methods: {
    formatCurrency,
    list() {
      this.tablelist.push(this.cardlist);
    },
  },
};
</script>

<style scoped></style>
